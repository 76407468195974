import React from 'react';
import './App.css';

declare var google: any;

let map: any;

declare global {
  interface Window {
      google: any;
  }
}

type TopRatedPlaceResultType = {
    name: string;
    rating: number;
    user_ratings_total: number;    
    // ... other properties as needed
  } | null;

function initMap(): void {
  map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 8
  });
}

function getDirections(setTopRatedPlacesResult: React.Dispatch<React.SetStateAction<any>>, setTopRatedPlaceResult: React.Dispatch<React.SetStateAction<any>>): void {
    const pointA = (document.getElementById('pointA') as HTMLInputElement).value;
    const pointB = (document.getElementById('pointB') as HTMLInputElement).value;
    const placeType = (document.getElementById('placeType') as HTMLSelectElement).value;

    if (pointA && pointB && placeType) {
        const directionsService = new google.maps.DirectionsService();
        const placesService = new google.maps.places.PlacesService(map);
        const waypoints: any[] = [];
        const tempLocations: any[] = [];

        directionsService.route({
            origin: pointA,
            destination: pointB,
            travelMode: 'DRIVING'
        }, (result: any, status: any) => {
            if (status === 'OK') {
                const pathPoints = result.routes[0].overview_path;
                const samplePoints: any[] = [];
                
                const maxSamples = 20;
                let stepSize = Math.floor(pathPoints.length / maxSamples);

                if (stepSize < 1) stepSize = 1;

                for (let i = 0; i < pathPoints.length; i += stepSize) {
                    samplePoints.push(pathPoints[i]);
                }

                // Trim the samplePoints if it exceeds maxSamples due to rounding
                while (samplePoints.length > maxSamples) {
                    samplePoints.pop();
                }

                let promises = samplePoints.map((point) => {
                    return new Promise<void>((resolve) => {
                        placesService.nearbySearch({
                            location: point,
                            radius: 1000,
                            type: placeType
                        }, (places: any[], status: any) => {
                            if (status === google.maps.places.PlacesServiceStatus.OK) {
                                const topRatedPlace = places.filter(place => place.rating > 3.0).sort((a, b) => b.rating - a.rating)[0];
                                
                                if (topRatedPlace && !tempLocations.some(place => place.place_id === topRatedPlace.place_id) && topRatedPlace.user_ratings_total > 50) {
                                    tempLocations.push(topRatedPlace);
                                }
                            }
                            resolve();  // Resolve promise after nearbySearch completes
                        });
                    });
                });

                Promise.all(promises).then(() => {   
                    if(tempLocations[0] !== undefined){
                        let currentHighestRating = tempLocations[0].rating;
                        let currentHighestRatedLocation = tempLocations[0];
                        for (let i = 0; i < tempLocations.length; i++) {
                            if (tempLocations[i].rating > currentHighestRating) {
                                currentHighestRating = tempLocations[i].rating;
                                currentHighestRatedLocation = tempLocations[i];
                            }
                        }
                
                        waypoints.push({
                            location: currentHighestRatedLocation.geometry.location,
                            stopover: true
                        });
    
                        setTopRatedPlacesResult(tempLocations);
                        setTopRatedPlaceResult(currentHighestRatedLocation);
                
                        getDirectionsIncludingWaypoints(pointA, pointB, waypoints, directionsService);
                    } else {
                        alert('No result found with a rating above 3.0 stars.');
                    }                             
                });  
            }
        });
    } else {
        alert('Please enter point A, point B, and the type of place.');
    }
}

function getDirectionsIncludingWaypoints(pointA: string, pointB: string, waypoints: any[], directionsService: any) {
    directionsService.route({
        origin: pointA,
        destination: pointB,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: 'DRIVING'
    }, (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
            const directionsRenderer = new google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            directionsRenderer.setDirections(result);
        }
    });
}

function loadGoogleMapsScript(src: any) {
    const script = document.createElement('script');
    script.id = 'googleMapsScript';
    script.src = src;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
}

function openMaps(place: TopRatedPlaceResultType) {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place!.name)}`;
    window.open(mapsUrl, '_blank');
}



function App() {
    const [topRatedPlacesResult, setTopRatedPlacesResult] = React.useState<TopRatedPlaceResultType[]>();
    const [topRatedPlaceResult, setTopRatedPlaceResult] = React.useState<TopRatedPlaceResultType>();
    React.useEffect(() => {
        let scriptLoaded = false;  // Flag to track if the script has been loaded
      
        const intervalId = setInterval(() => {
          if (window.google && window.google.maps) {
            initMap();
            clearInterval(intervalId);
          } else if (!scriptLoaded) {
            scriptLoaded = true;  // Set flag to true immediately before fetch
            fetch('https://us-central1-ivory-channel-400513.cloudfunctions.net/generateGoogleScriptSrc', {
                method: 'GET',
                mode: 'cors'
            })
            .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                loadGoogleMapsScript(data.src);
            })
            .catch(error => {
                console.log('Error:', error);
                scriptLoaded = false;  // If there was an error, allow another attempt
            });
          }
        }, 100);
      
        return () => clearInterval(intervalId);  // Cleanup interval on component unmount
      
      }, []);      
  return (
    <>
      <input id="pointA" type="text" placeholder="Enter point A" />
      <input id="pointB" type="text" placeholder="Enter point B" />

      <select id="placeType">
        <option value="accounting">Accounting</option>
        <option value="airport">Airport</option>
        <option value="amusement_park">Amusement Park</option>
        <option value="aquarium">Aquarium</option>
        <option value="art_gallery">Art Gallery</option>
        <option value="atm">ATM</option>
        <option value="bakery">Bakery</option>
        <option value="bank">Bank</option>
        <option value="bar">Bar</option>
        <option value="beauty_salon">Beauty Salon</option>
        <option value="bicycle_store">Bicycle Store</option>
        <option value="book_store">Book Store</option>
        <option value="bowling_alley">Bowling Alley</option>
        <option value="bus_station">Bus Station</option>
        <option value="cafe">Cafe</option>
        <option value="campground">Campground</option>
        <option value="car_dealer">Car Dealer</option>
        <option value="car_rental">Car Rental</option>
        <option value="car_repair">Car Repair</option>
        <option value="car_wash">Car Wash</option>
        <option value="casino">Casino</option>
        <option value="cemetery">Cemetery</option>
        <option value="church">Church</option>
        <option value="city_hall">City Hall</option>
        <option value="clothing_store">Clothing Store</option>
        <option value="convenience_store">Convenience Store</option>
        <option value="courthouse">Courthouse</option>
        <option value="dentist">Dentist</option>
        <option value="department_store">Department Store</option>
        <option value="doctor">Doctor</option>
        <option value="drugstore">Drugstore</option>
        <option value="electrician">Electrician</option>
        <option value="electronics_store">Electronics Store</option>
        <option value="embassy">Embassy</option>
        <option value="fire_station">Fire Station</option>
        <option value="florist">Florist</option>
        <option value="funeral_home">Funeral Home</option>
        <option value="furniture_store">Furniture Store</option>
        <option value="gas_station">Gas Station</option>
        <option value="gym">Gym</option>
        <option value="hair_care">Hair Care</option>
        <option value="hardware_store">Hardware Store</option>
        <option value="hindu_temple">Hindu Temple</option>
        <option value="home_goods_store">Home Goods Store</option>
        <option value="hospital">Hospital</option>
        <option value="insurance_agency">Insurance Agency</option>
        <option value="jewelry_store">Jewelry Store</option>
        <option value="laundry">Laundry</option>
        <option value="lawyer">Lawyer</option>
        <option value="library">Library</option>
        <option value="light_rail_station">Light Rail Station</option>
        <option value="liquor_store">Liquor Store</option>
        <option value="local_government_office">Local Government Office</option>
        <option value="locksmith">Locksmith</option>
        <option value="lodging">Lodging</option>
        <option value="meal_delivery">Meal Delivery</option>
        <option value="meal_takeaway">Meal Takeaway</option>
        <option value="mosque">Mosque</option>
        <option value="movie_rental">Movie Rental</option>
        <option value="movie_theater">Movie Theater</option>
        <option value="moving_company">Moving Company</option>
        <option value="museum">Museum</option>
        <option value="night_club">Night Club</option>
        <option value="painter">Painter</option>
        <option value="park">Park</option>
        <option value="parking">Parking</option>
        <option value="pet_store">Pet Store</option>
        <option value="pharmacy">Pharmacy</option>
        <option value="physiotherapist">Physiotherapist</option>
        <option value="plumber">Plumber</option>
        <option value="police">Police</option>
        <option value="post_office">Post Office</option>
        <option value="primary_school">Primary School</option>
        <option value="real_estate_agency">Real Estate Agency</option>
        <option value="restaurant">Restaurant</option>
        <option value="roofing_contractor">Roofing Contractor</option>
        <option value="rv_park">RV Park</option>
        <option value="school">School</option>
        <option value="secondary_school">Secondary School</option>
        <option value="shoe_store">Shoe Store</option>
        <option value="shopping_mall">Shopping Mall</option>
        <option value="spa">Spa</option>
        <option value="stadium">Stadium</option>
        <option value="storage">Storage</option>
        <option value="store">Store</option>
        <option value="subway_station">Subway Station</option>
        <option value="supermarket">Supermarket</option>
        <option value="synagogue">Synagogue</option>
        <option value="taxi_stand">Taxi Stand</option>
        <option value="tourist_attraction">Tourist Attraction</option>
        <option value="train_station">Train Station</option>
        <option value="transit_station">Transit Station</option>
        <option value="travel_agency">Travel Agency</option>
        <option value="university">University</option>
        <option value="veterinary_care">Veterinary Care</option>
        <option value="zoo">Zoo</option>
      </select>
      <button onClick={() => getDirections(setTopRatedPlacesResult, setTopRatedPlaceResult)}>Get Directions</button>
      <div id="primary-result">
                    {topRatedPlaceResult && (
                        <div onClick={() => openMaps(topRatedPlaceResult)}>
                            <h3>Best Result</h3>
                            <p>Name: {topRatedPlaceResult.name}</p>
                            <p>Rating: {topRatedPlaceResult.rating}</p>
                            <p>Total Rating Count: {topRatedPlaceResult.user_ratings_total}</p>
                            {/* ... Add more details as needed */}
                        </div>
                )}
      </div>
      <div id="map"></div>
      <div id="results-table">
        {topRatedPlacesResult && topRatedPlacesResult.length > 0 && (
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Rating</th>
                        <th>Total Rating Count</th>
                        {/* ... Add more headers as needed */}
                    </tr>
                </thead>
                <tbody>
                    {topRatedPlacesResult.map((place, index) => (
                        <tr key={index} onClick={() => openMaps(place)}>
                            <td>{place!.name}</td>
                            <td>{place!.rating}</td>
                            <td>{place!.user_ratings_total}</td>
                            {/* ... Add more data cells as needed */}
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
      </div>      
    </>
  );
}

export default App;
